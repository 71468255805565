$orangeColor:#E9693A;
$blueHighlight: #62B1EE;
$lightHighlightBackgound: #EAF3FC;
$whiteColor: #FFFFFF;
$errorColor: #AD1C1C;
$orangeColor:#E9693A;
$successColor: #2E7D12;
$bgGreen: #D3E9C7;

.single-feed-box {
	padding:12px 0px 18px 0px;
	background-color:#FFFFFF;
	margin:10px 0px;

	.row {
		margin-left:0px;
		margin-right: 0px;
	}
}

.filter-meeting-textbox {
	margin: 10px 0px;
	font-size: 12px;
	color: #666666;
	font-weight: bold;

	.filter-middot {
		padding: 0px 10px;
	}

	.back-link {
		float: right;
		cursor: pointer;
		color: #62B1EE;
	}
}

.feed-top-row {
	width:100%;
	padding:0px 15px 10px 0px;
	border-bottom:1px solid #EEEEEE;

	.image-box {
		padding-right: 0px;
		padding-left: 0px;

		.feed-avatar {
			cursor: pointer;
		}

		.initial-box {
			cursor: pointer;
		}
	}

	.name-box {
		margin-top:8px;
		padding-left: 0px;
	}

	.voting-box {
		float:right;
		width:25%;
		font-size:12px;
		text-align:right;
		margin-top:14px;

		.upvoteIcon, .downvoteIcon {
			width:18px;
			height:auto;
			margin-right:5px;
			margin-left:8px;
			margin-top:-2px;
			cursor: pointer;
		}

		.flagIcon {
			width:12px;
			margin-top:-2px;
			margin-right:5px;
			margin-left:8px;
			height:auto;
			cursor: pointer;
		}

		.vote-icon-span {
			padding-right:5px;
		}

		.active-vote-icon {
			color: $orangeColor;
		}

		.active-flag {
			color: $orangeColor;
		}
	}

	.name-box-teams {
		float:left;
		width:100%;
	}

	.name-box-teams {	
		font-size:12px;
	}

	.name-box-title {
		font-weight:normal;
		cursor: pointer;
		width: 100%;
		float: left;
	}
}

.feed-content-row {
	padding:15px 15px 0px 0px;
	width:100%;

	.rating-box {
		width:12%;
		float:left;
		position:relative;
	}

	.comment-outer-box {
		padding-left: 0px;
		padding-right: 0px;
	}

	.comment-box {
		line-height:21px;
		font-style:regular;
	}

	.rating-score-circle {
		text-align:center;
		width:25px;
		height:25px;
		border-radius:50%;	
		font-size:12px;
		color:#ffffff;
		font-weight:bold;
		line-height:24px;
		margin:auto;
	}

	.feed-time-row {
		width:100%;
		padding:10px 15px 0px 0px;

		.time-meeting-box {
			width:87%;
			float:left;
			font-size:10px;

			.timeIcon, .meetingIcon {
				width:13px;
				height:13px;
				margin-bottom:3px;
				margin-right:5px;
			}

			.meetingIcon {
				margin-left:10px;
			}
		}
	}
}

.flag-modal-container {
	
	padding: 60px 20px;
	background-color: #F7F8F9;

	.flag-title {
		color: $orangeColor;
		font-size: 28px;
		text-align: center;
		
		.flag-title-content {
			display:table;
			margin:0 auto;

			.flagIcon {
				height: 28px;
				padding-bottom: 6px;
			}
			.flag-title-text {
				padding-left: 8px;
			}
		}
	}

	.flag-description {
		text-align: center;
		padding: 20px 0px;
	}


	.row {
		width:100%;
		margin:0px;
	}

	.post-button-container {
		padding: 0px 20px;
		margin:20px;
		text-align: center;

		.btn {
			padding: .3rem .65rem;
		}
		
		.btn-primary:hover {
			color: #FFFFFF;
			background-color: $blueHighlight;
		}

		.btn-primary {
			font-size: 12px;
			border-radius: 22px;
			color: $whiteColor;
			border-color: $blueHighlight;
			font-weight: 600;
			font-variant: small-caps;
			line-height: 20px;
			width: 240px;
			height: 44px;
			background-color: #222222;
		}

		div.err-msg {
			padding: 10px 0;
			font-size: 12px;
			font-weight: 600;
			color: $errorColor;
		}
	}

	.flag-reason-box {
		.reason-box-container {
			margin-top: 15px;

			.reason-textarea {
				width: 100%;
				height: 170px;
				border-radius: 10px;
				background-color: $whiteColor;
				border:0px;
				padding:10px;
				font-size: 12px;
				color:#464646;
				resize: none;
			}
		}
	}
	
	.btn {
		padding: .3rem .65rem;
	}
	
	.btn-outline-primary:hover, .btn-outline-primary:focus {
		color: #FFFFFF;
		background-color: $blueHighlight;
	}

	.btn-outline-primary {
		font-size: 12px;
		border-radius: 20px;
		color: $blueHighlight;
		border-color: $blueHighlight;
		font-weight: 600;
		font-variant: small-caps;
		line-height: 14px
	}

	.btn-outline-primary:active {
		color: #FFFFFF;
		background-color: $blueHighlight !important;
		box-shadow: none !important;
		border: 1px solid $blueHighlight !important;
	}
}