$blueHighlight: #62B1EE;
$lightHighlightBackgound: #EAF3FC;
$whiteColor: #FFFFFF;
$bgRed: #FFABAB;
$errorColor: #AD1C1C;
$orangeColor:#E9693A;
$successColor: #2E7D12;
$bgGreen: #D3E9C7;

.page-inner-container {
	.user-list-box {
		margin: 5px 0px;
	}

	.not-found-div {
		padding: 20px;
	}

	.card-deck {
		margin-left: -10px;
	}

	.card {
		border: 0px;
		border-radius: 0px;
		margin: 5px 10px;
		flex: none;
		cursor: pointer;
	}

	.card-body {
		width:305px;
	}

	.name-box {
		float: left;
		padding-right:0px;
		margin-top: 7px;
	}

	.name-box-teams {
		float:left;
		width:100%;
		font-weight: 600;
	}
	
	.name-box-title {
		float: left;
		font-weight: 600;
		width: 100%;
	}

	.button-row {
		float:left;
	}

	.name-box-teams {	
		font-size:12px;
		font-weight: 500;

		.team-span {
			padding-right: 5px;
		}

		.team-middot {
			padding-right:5px;
		}
	}

	.button-row {
		margin-top:20px;
	}

	.btn {
		padding: .3rem .65rem;
	}

	.btn-outline-primary:hover {
		color: #FFFFFF;
		background-color: $blueHighlight;
	}

	.btn-outline-primary:active {
		color: #FFFFFF;
		background-color: $blueHighlight !important;
		box-shadow: none !important;
		border: 1px solid $blueHighlight !important;
	}

	.btn-outline-primary {
		font-size: 9px;
		border-radius: 20px;
		margin-right: 10px;
		color: $blueHighlight;
		border-color: $blueHighlight;
		font-weight: 600;
		font-variant: small-caps;
		line-height: 14px
	}

	.success-msg {
		font-size: 12px;
		font-weight: 600;
		background-color: $bgGreen;
		color: $successColor;
		padding: 10px 20px;
		opacity: 1;
		transition: opacity 200ms ease-in-out, padding 200ms ease-in-out;
	}

	.success-msg-show {
		opacity: 1;
	}

	.success-msg-hidden {
		opacity: 0;
		padding: 0 20px;
	}

	.failure-msg {
		font-size: 12px;
		font-weight: 600;
		background-color: $bgRed;
		color: $errorColor;
		padding: 10px 20px;
		opacity: 1;
		transition: opacity 200ms ease-in-out, padding 200ms ease-in-out;
	}

	.failure-msg-show {
		opacity: 1;
	}

	.failure-msg-hidden {
		opacity: 0;
		padding: 0 20px;
	}
}

.modal-dialog {
	margin:2.75rem auto;
}
.modal-content {
	border-radius: 1rem;
}

.single-user-box {
	width: 49%;
	height: 118px;
	background-color: #FFFFFF;
	float: left;
	margin: 5px 0px;
}

.user-image-box {
	padding-left: 0px;
	float: left; 
}

.give-modal-container {
	height: 630px;
}

.give-modal-container, .tag-modal-container {
	padding:0px;
	background-color: #F7F8F9;

	.give-header-row {
		padding:20px;
		background-color: $lightHighlightBackgound;

		.back-button-img {
			position: absolute;
			height: 26px;
			width: 26px;
			left: 0;
			margin-left:15px;
			cursor: pointer;
		}	
	}

	.team-span {
		padding-right: 5px;
	}

	.team-middot {
		padding-right:5px;
	}

	.row {
		width:100%;
		margin:0px;
	}

	.name-box, .profile-button {
		padding-left: 0px;
		padding-top:8px;
	}

	.name-box-teams {	
		font-size:12px;
	}

	.profile-button {
		text-align: right;
	}

	.rating-grid-container {
		padding: 15px 20px;

		.col {
			padding:0px;
			text-align: center;
		}

		.rating-number-box {
			text-align: center;
		
			.rating-number-text {
				background-color: $whiteColor;
				height:100px;
				margin: auto;
				width:90%;
				cursor: pointer;
			}

			.rating-text-font {
				font-size: 40px;
				font-weight: 600;
				padding:40px 20px;
			}
		}

		.rating-text-box {
			margin-top: 10px;
			text-align: center;

			span {
				width: 100%;
				font-size: 12px;
				line-height: 16px;
			}

			.rating-text-name {
				font-weight: 600;
				font-size: 14px;
			}
		}
	}

	.meeting-tag-container {
		padding: 0px 20px;
		margin-top:5px;

		.meeting-tag-icon {
			width: 17px;
			height: auto;
			margin-right:10px;
			margin-top: -3px;
		}

		.meeting-tag-text {
			padding:10px;
			background-color: $whiteColor;
			font-size: 12px;
			cursor: pointer;
		}
	}

	.post-button-container {
		padding: 0px 20px;
		margin:20px;
		text-align: center;

		.btn {
			padding: .3rem .65rem;
		}
		
		.btn-primary:hover {
			color: #FFFFFF;
			background-color: $blueHighlight;
		}

		.btn-primary {
			font-size: 12px;
			border-radius: 22px;
			color: $whiteColor;
			border-color: $blueHighlight;
			font-weight: 600;
			font-variant: small-caps;
			line-height: 20px;
			width: 240px;
			height: 44px;
			background-color: #222222;
		}

		div.err-msg {
			padding: 10px 0;
			font-size: 12px;
			font-weight: 600;
			color: $errorColor;
		}
	}

	.comment-box-container {
		padding: 0px 20px;
		margin-top: 15px;

		.comment-textarea {
			width: 100%;
			height: 170px;
			border-radius: 10px;
			background-color: $whiteColor;
			border:0px;
			padding:10px;
			font-size: 12px;
			color:#464646;
			resize: none;
		}
	}

	.btn {
		padding: .3rem .65rem;
	}
	
	.btn-outline-primary:hover, .btn-outline-primary:focus {
		color: #FFFFFF;
		background-color: $blueHighlight;
	}

	.btn-outline-primary {
		font-size: 12px;
		border-radius: 20px;
		color: $blueHighlight;
		border-color: $blueHighlight;
		font-weight: 600;
		font-variant: small-caps;
		line-height: 14px
	}

	.btn-outline-primary:active {
		color: #FFFFFF;
		background-color: $blueHighlight !important;
		box-shadow: none !important;
		border: 1px solid $blueHighlight !important;
	}
}

.tag-modal-container {

	background-color: $whiteColor;

	.row {
		justify-content: center;
	}
	.meeting-user-name {
		color: $orangeColor;
		font-size: 18px;
		font-weight: 600;
		padding-left:5px;
		line-height: 22px;
	}

	.meeting-title {
		font-weight: 600;
		text-align: center;
		width: 100%;
	}

	.meeting-popular-title {
		text-align: center;
		font-variant: small-caps;
		font-size: 12px;
		color: #777777;
		margin: 10px 0px;
		font-weight: 600;

		.single-line-img {
			height: 1px;
			width: 79px;
			margin: 11px 25px;
		}
	}

	.popular-button {
		margin-bottom: 20px;

		.btn-outline-primary {
			background-color: #FDF7E6;
			border: 0px;
		}
	}

	.btn-outline-primary {
		height: 32px;
		color: #464646;
		border-radius: 16px;
		font-variant: normal;
		font-weight: 500;
		margin: 0px 7px;
		padding-left: 20px;
		padding-right: 20px;
		border: 1.5px solid #EEEEEE;
	}

	.btn-outline-primary:hover {
		color: #FFFFFF;
		background-color: $orangeColor;
	}

	.all-tags-row {
		padding: 10px 30px;

		.tagname-box:hover {
			background-color: #FDF7E6;
			cursor: pointer;
		}
		.tag-list-row {
			max-height: 300px;
			overflow-y: scroll;
			justify-content: left;
		}
		.tagname-box {
			width: 50%;
			float: left;
			height: 58px;
			border-right: 1px solid #EEEEEE;
			padding: 5px 10px 0px 10px;
		}
		.tag-full-name {
			font-size: 12px;
		}
		.tag-used-number {
			font-size: 10px;
			color: #999999;
		}
		.search-tag-row {
			margin: 20px 0px 20px 0px;
		}
		.tag-search-box {
			font-size: 12px;
			border-radius: 20px;
			background-color: #F2F2F2;
			color: #AAAAAA;
			border: none;
			padding-left:25px;
			height: 40px;
		}
		.search-tag-form {
			width: 99%;
		}
	}
}