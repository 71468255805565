.page-not-found {
  color: #AAAAAA;
  font-size: 60px;
  display: block;
  text-align: center;
  margin-top: 150px;
  height: 50px;
}

.goback-link {
	text-align: center;
	display: block;
	margin: 20px 0;
}