$secondaryFont: 'Muli', sans-serif;
$fontWeightBold:600;
$whiteColor:#FFFFFF;
$lightGrey: #F7F8F9;
$orangeColor:#E9693A;
$redColor: #FF0000;


/* for leaderboard modal */

.leaderboard-modal-container {

	min-height: 500px;
	padding: 0px;

	.row {
		margin-left: 0px;
		margin-right: 0px;
		width: 100%;
	}

	.bold-title-heading {
		font-weight: $fontWeightBold;
		width: 100%;
		text-align: center;
		margin-bottom: 10px;
	}

	.leaderboard-header-row {
		text-align: center;
		display: block;
		font-size: 14px;
		margin: 20px 0px;

		.bold-title {
			font-weight: $fontWeightBold;
		}

		.time-row {
			font-size: 12px;
			color: #AAAAAA;
		}
	}

	.participation-number {
		padding-left:50px;
	}

	.expectation-number {
		padding-right:50px;
	}
	
	.table-striped tbody tr:nth-of-type(odd) {
		background-color: #FFF4F1;
	}

	.leaderboard-numbers-section {
		margin:10px 0px;

		.col {
			text-align: center;
		}

		.total-fb-number, .total-expec-number {
			color: #5A80A3;
			font-size: 45px;
			padding: 14px;
			font-weight: $fontWeightBold;
		}

		.total-fb-title, .total-expec-title {
			font-size: 10px;
			color: #777777;
		}
	}

	.happening-now {
		margin: auto;
		padding-bottom: 10px;

		.red-dot {
			width: 10px;
			height: 10px;
			border-radius: 5px;
			background-color: $redColor;
			margin-right: 6px;
			margin-top: 4px;
			display: inline-block;
		}

		.live-tag {
			color: $redColor;
			font-family: "Muli", sans-serif;
			font-weight: 600;
			display: inline-block;
		}
	}

	.leaderboard-table {

		thead {
			background-color: #F7F8F9;
			color: #AAAAAA;
			font-size: 10px;
			display: block;

			.total-tr-column {
				font-size: 8px;
				text-align: center;
				width: 60px;
			}
			td {
				padding: 5px 10px;
			}
			.rating-td-column {
				text-align: center;
				width: 36px;	
			}
			.number-td-column {
				width: 74px;
			}
			.name-td-column {
				width: 179px;
			}
			.pic-td-column {
				width: 41px;
			}
		}

		tbody {
			color: #464646;
			border-bottom: 1px solid #DDDDDD;
			display: block;
			height: 100%;
			max-height: 500px;
    	overflow: scroll;

			td {
				padding: 5px 8px;
				height: 60px;
				vertical-align: middle;
			}

			.total-td-column {
				text-align: center;
				font-weight: $fontWeightBold;
				font-family: $secondaryFont;
				font-size: 14px;
				width: 60px;
				border-left: 1px solid #DDDDDD;
			}

			.number-td-column {
				font-size: 50px;
				padding: 0px 10px 0px 0px;
				vertical-align: bottom;
				text-align: right;
				color: #EEEEEE;
				line-height: 35px;
				font-weight: 600;
				width: 74px;
			}

			.rating-td-column {
				text-align: center;
				border-left: 1px solid #DDDDDD;
				font-family: $secondaryFont;	
				font-size: 14px;
				width: 36px;			
			}

			.name-td-column {
				padding-right: 0px;
				cursor: pointer;
				width: 179px;
			}

			.pic-td-column {
				padding:5px 0px;
				cursor: pointer;
				width: 41px;

				.avatar-wrapper {
					height: 35px;
					width: 35px;
				}
			}
		}
		.loading-body {
			border-bottom: none;
		}
		.loading-div {
			padding: 40px;
			text-align: center;
		}
	}
}

.meetings-list-box {
	margin-left: 0px;
	margin-right: 0px;

	.row {
		margin: 0px;
	}

	.col {
		padding-left: 0px;
		padding-right: 0px;
	}

	.date-item-row {
		color: #AAAAAA;
		font-size: 11px;
		font-weight: 600;
		font-variant: small-caps;
		margin:10px 0px;
		
		.date-button-box {
			color: #62B1EE;
			font-size: 9px;
			font-weight: $fontWeightBold;

			.button-wrapper {
				text-align: center;

				.row:hover {
					color: $orangeColor;
				}

				img, span {
					cursor: pointer;
				}
			}

			.link-icons {
				height:14px;
				width: 14px;
				margin-right: 7px;
				margin-top: 2px;
			}
		}
	}

	.meeting-title {
		font-family: $secondaryFont;
		font-weight: $fontWeightBold;
	}

	.meeting-main-box {
		padding:20px;
		border-right: 1px solid #EEEEEE;
		cursor: pointer;
	}

	.single-meeting-row {
		background-color: $whiteColor;
		border-bottom: 1px solid $lightGrey;

		.meeting-status {
			font-family: "Muli", sans-serif;
			font-weight: 600;
			padding-top: 10px;

			.red-dot {
				width: 10px;
				height: 10px;
				border-radius: 5px;
				background-color: $redColor;
				margin-right: 6px;
				margin-top: 4px;
			}

			.live-tag {
				color: $redColor;
			}
		}

		.participant-list-row {

			padding-top: 10px;

			.avatar-wrapper {
				height: 35px;
				width: 35px;
			}

			.user-image-box {
				padding:2px;
			}

			.extra-number-text {
				font-size: 12px;
				font-family: $secondaryFont;
				padding: 10px;
			}
		}

		.meetingIcon {
			width: 17px;
			height: 17px;
			margin-right: 7px;
		}

		.meeting-button-box {
			color: #62B1EE;
			font-size: 9px;
			font-weight: $fontWeightBold;

			.button-wrapper {
				padding-top: 28px;
				text-align: center;

				.row:hover {
					color: $orangeColor;
				}

				img, span {
					cursor: pointer;
				}
			}

			.row {
				padding-bottom: 10px;
			}

			.link-icons {
				height:14px;
				width: 14px;
				margin-right: 7px;
				margin-top: 2px;
			}
		}
	}
}