$fontWeightBold: 600;
$secondaryFont: 'Muli', sans-serif;
$textBlack:#464646;
$textLightGrey:#AAAAAA;
$lightHighlightBackgound:#FFF4F1;
$orangeColor:#E9693A;
$whiteColor:#FFFFFF;
$textGrey: #777777;
$barBackground: #EEEEEE;

.coming-soon {
  color: #AAAAAA;
  font-size: 60px;
  display: block;
  text-align: center;
  margin-top: 150px;
}

.single-box {
	margin-top: 0px;
}

.profile-feedback-error {
	text-align: center;
	padding-top: 20px;
}

.right-user-box {
	float: left;
	width: 100%;
	background-color: $whiteColor;
	margin-bottom: 10px;
	
	.profile-top-area {
		float: left;
		width: 100%;
		height: 40px;
		background-color: $lightHighlightBackgound;
		font-weight: 600;
		padding: 10px 20px;
		font-size: 12px;
	}
	
	.profile-details-container {
		padding: 10px 20px;
		float: left;

		.profile-stats-section {
			padding-bottom: 10px;

			.profile-stats-section-label {
				font-size: 10px;
				color: $textLightGrey;
			}

			.profile-distribution-bar {
				height: 20px;
				width: 100%;
				background: $barBackground;
				border-radius: 10px;

				.profile-distibution-bar-four {
					height: 100%;
					background: #62B1EE;
					border-radius: 10px;
				}

				.profile-distibution-bar-three {
					height: 100%;
					background: #83BD63;
					border-radius: 10px;
				}

				.profile-distibution-bar-two {
					height: 100%;
					background: #E79F3C;
					border-radius: 10px;
				}

				.profile-distibution-bar-one {
					height: 100%;
					background: #D65745;
					border-radius: 10px;
				}
			}
		}
	}
	
	.user-top-area {
		float: left;
		width: 100%;
		height: 65px;
		background-color: $orangeColor;
	}

	.user-logo-box {
		float: left;
		margin-left: 110px;
		margin-top: 10px;
		position: absolute;

		.avatar-wrapper {
			width: 75px;
			height: 75px;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
		}

		.user-logo {
			width: 50%;
		}
	}

	.user-numbers-section, .user-name-section, .user-company-section {
		float: left;
		width: 100%;
		text-align: center;
		padding: 0px 20px;
	}

	.user-name-section {
		font-size: 16px;
		font-weight: $fontWeightBold;
		margin-top: 25px;
	}

	.user-company-section {
		font-size: 12px;
		color: $textLightGrey;
		font-family: $secondaryFont;
	}

	.user-numbers-section {
		padding-top: 20px;
	}

	.participation-number, .expectation-number {
		float: left;
		width: 50%;
		text-align: center;
	}

	.total-fb-number, .total-expec-number {
		font-size: 32px;
		color: #5A80A3;
		font-weight: $fontWeightBold;
		padding: 5px 0px;
	}

	.total-fb-title, .total-expec-title {
		font-size: 10px;
		color: #777777;
	}

	.total-expec-number {
		color: #E79F3C;
	}

	.time-period-text {
		text-align: center;
		font-size: 10px;
		color: #777777;
		padding: 10px 0px;
	}
}

.profile-feed-content-row {
	padding:15px 15px 20px 0px;
	width:100%;

	.rating-box {
		width:12%;
		float:left;
		position:relative;
	}

	.comment-outer-box {
		padding-left: 20px;
		padding-right: 0px;
	}

	.comment-box {
		line-height:21px;
		font-style:regular;
	}

	.rating-score-circle {
		text-align:center;
		width:35px;
		height:35px;
		border-radius:50%;	
		font-size:12px;
		color:#ffffff;
		font-weight:bold;
		line-height:35px;
	}
}

.profile-feed-time-row {
	width:100%;
	border-top: 0.5px solid #EEEEEE;
	padding: 16px 10px 0px 10px;

	.time-meeting-box {
		// width:87%;
		float:left;
		font-size:10px;

		.timeIcon, .meetingIcon {
			width:13px;
			height:13px;
			margin-bottom:3px;
			margin-right:5px;
		}

		.meetingIcon {
			margin-left:10px;
		}
	}

	.voting-box {
		float:right;
		width:25%;
		font-size:12px;
		text-align:right;

		.upvoteIcon, .downvoteIcon {
			width:18px;
			height:auto;
			margin-right:5px;
			margin-left:8px;
			margin-top:-2px;
			cursor: pointer;
		}

		.flagIcon {
			width:12px;
			margin-top:-2px;
			margin-right:5px;
			margin-left:8px;
			height:auto;
			cursor: pointer;
		}

		.vote-icon-span {
			padding-right:5px;
		}

		.active-vote-icon {
			color: $orangeColor;
		}

		.active-flag {
			color: $orangeColor;
		}
	}
}

.profile-single-feed-box {
	padding:12px 0px 18px 0px;
	background-color:#FFFFFF;
	margin:10px 0px;

	.row {
		margin-left:0px;
		margin-right: 0px;
	}
}