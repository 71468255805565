/* Variables */
$vin-blue: #5bb7db;
$vin-green: #60b044;
$vin-red: #ff0000;
$lightGrey: #F7F8F9;
$fontWeightBold:600;
$secondaryFont: 'Muli', sans-serif;
$textBlack:#464646;
$textLightGrey:#AAAAAA;
$lightHighlightBackgound:#FFF4F1;
$orangeColor:#E9693A;
$whiteColor:#FFFFFF;

/* Styles */
.container {
	max-width:990px;
}

html, body, #app, .login-container {
   height: 100%;
}

#mobile-app {
    visibility:hidden;
	display: none;
}
#app {
    visibility:visible;
}

@media screen and (max-width : 700px){
    #mobile-app {
        width: 100%;
        height: 100%;
        visibility: visible;
		display: block !important;
		background-color: #EEEEEE;
    }
    #app {
        visibility:hidden;
        display: none;
    }
}

.displayNone {
	display: none;
}

.login-container {

	height:100%;

	.row {
		height:100%;
		margin: 0px;
	}

	.login-left-column, .login-right-column {
		background-color: $orangeColor;
		min-width: 500px;
		display: flex;

		.fb-bg-logo {
			position: absolute;
			bottom: 0;
			height: 528px;
			width: 477px;
		}
	}

	.login-left-image {
		width: 400px;
	}

	.login-left-container, .login-right-container {
		max-width: 480px;
		margin-left: auto;
		margin-right: 0px;
		margin-bottom: auto;
		margin-top: auto;
		position: relative;
	}

	.login-right-container {
		margin-right: auto;
		margin-left: 0px;
		padding-left: 60px;

		.row {
			margin: 20px 0px;
		}

		.fb-logo-image {
			width: 300px;
			height: 48px;
			margin-bottom: 10px;
		}

		.fb-title {
			font-weight: $fontWeightBold;
			font-size: 28px;
		}

		.web-word-text {
			color: #BBBBBB;
		}

		.app-logo-image, .google-logo-image {
			height: 40px;
			width: 145px;
			margin-right: 20px;
		}
		.get-the-app {
			font-size: 14px;
			margin-top: 60px;
			margin-bottom: 10px;
			font-variant: small-caps;
		}

		.store-img-row {
			margin-top: 0px;
		}

		.privacy-policy-row {

			font-size: 12px;
			margin-top: 25px;
			font-family: $secondaryFont;
			text-transform: capitalize;
			color: #999999;
			font-weight: $fontWeightBold;

			a {
				color: #2D9CDB;
			}

			.paw-logo-image {
				width: 14px;
				height: 17px;
				margin-right: 10px;
			}	
		}
	}
	
	.login-right-column {
		background-color: $whiteColor;
		min-width: 500px;
	
		.btn {
			padding: .3rem .65rem;
		}
		
		.btn-primary:hover {
			box-shadow: 5px 5px 2px #DDDDDD;
			background-color: #222222;
		}

		.btn-outline-primary:hover {
			box-shadow: 5px 5px 2px #DDDDDD;
			background-color: #FFFFFF;
		}

		.btn-primary, .btn-outline-primary {
			font-size: 14px;
			border-radius: 40px;
			color: $whiteColor;
			border-color: #222222;
			font-weight: $fontWeightBold;
			font-variant: small-caps;
			line-height: 20px;
			width: 320px;
			height: 50px;
		}

		.btn-primary {
			background-color: #222222;
		}

		.btn-outline-primary {
			color: #222222;
		}
	}
}

#pageContainer {
	padding:90px 15px 15px 15px;
}

.page-inner-container {
	padding-left:0px;
	padding-right: 0px;
	float:left;
	width:100%;
}

.navbar {
	padding:0rem 0rem;
}

.dropdown-menu {
	
	margin-top: 0px;

	.drop-down-item {
		padding: 0.75rem 2rem !important;
		font-size: 12px;
	}

	.dropdown-item.active, .dropdown-item:active {
		background-color: inherit;
	}
}
.navbar-expand-lg .navbar-nav {
	a {
		padding:1.6rem 2rem;
		color: $textBlack;
		text-decoration: none;
	}

	.activeNav, a:hover {
		background-color :$whiteColor;
		color: $orangeColor !important;
		border-bottom: 3px solid $orangeColor;
	}

	.dropdown .activeNav, .dropdown a:hover {
		color: $textBlack !important;
		border-bottom: none;
	}

	.dropdown-menu a:hover {
		color: $orangeColor !important;
	}

	.nav-link {
		color: $textBlack;
	}
}

.navbar-outer-box {
	background-color:$whiteColor;
	box-shadow:0px 2px 4px rgba(0, 0, 0, 0.15);
	font-weight:$fontWeightBold;
	z-index: 100;
	position: fixed;
	width: 100%;

	.user-image-box {
		padding-top: 9px;
	}
}

.nav-logo {
	max-width: 100%;
  	height: auto;
}

.navbar-brand {
	padding:22px 0px 22px 0px;
	margin-left:10px;
	width:61px;
}

.nav>li>a {
	padding:22px 30px;
}

.filter-ribbon {
	height:65px;
	background-color:#FFFFFF;
	margin:0px;
	margin-bottom:5px;

	.filter-ribbon-nav {
		overflow: hidden;
		height: 35px;
		margin-top: 16px;

		.nav-link {
			padding:0.5rem 1.1rem;
		}

		a {
			font-family: $secondaryFont;
			color: #999999;
		}

		.active-nav {
			color: $orangeColor;
			font-weight: 600;
		}
	}

	.sort-wrapper {
		margin-top:23px;
		padding:0px 0px;
		text-align: center;
		font-size: 14px;

		.sortIcons {
			width:16px;
			height:auto;
			margin-left:10px;
			margin-right:10px;
			margin-top:-2px;
			cursor: pointer;
		}

		.flagBigIcon {
			height:16px;
			width:auto;
		}
	}
}

.sort-inner-box { 
	background-color:$lightHighlightBackgound;
}

.single-box {
	margin:5px 0px;
	float:left;
}

.container-fluid {
	padding:0;	
}

.loader {
	text-align: center;
	width: 100%;
}

.right-company-box {
	float:left;
	width:100%;
	background-color:$whiteColor;
	margin-top: 0px;

	.company-numbers-section, .company-name-section {
		float: left;
		width: 100%;
		text-align: center;
		padding: 0px 20px;
	}

	.company-name-section {
		font-size: 16px;
		font-weight: $fontWeightBold;
		margin: 25px 0px;
	}

	.participation-number, .expectation-number {
		float: left;
		width: 50%;
		text-align: center;
	}

	.total-fb-number, .total-expec-number {
		font-size: 32px;
		color: #5A80A3;
		font-weight: $fontWeightBold;
		padding: 5px 0px;
	}

	.total-fb-title, .total-expec-title {
		font-size: 10px;
		color: #777777;
	}

	.total-expec-number {
		color: #E79F3C;
	}

	.time-period-text {
		text-align: center;
		font-size: 10px;
		color: #777777;
		padding: 10px 0px;
	}
}

.right-filter-box {
	min-height:520px;
	float:left;
	width:100%;
	background-color:$whiteColor;

	
	.filter-top-area {
		float:left;
		width:100%;
		height:40px;
		background-color:$lightHighlightBackgound;
		font-weight:600;
		padding:10px 20px;
		font-size:12px;
	}
	
	.filter-details-container {
		padding:10px 20px;
		float: left;
	}

	.row {
		margin:10px 0px;
		border-bottom:0.5px solid #DDDDDD;
		padding-bottom:20px;
	}

	.team-list-row {
		border-bottom: 0px;

		.form-group {
			max-height: 400px;
			overflow-y: auto;
		}
	}

	.team-list-row.bottom {
		padding-bottom: 0px;
	}

	.filter-type-title {
		font-size: 10px;
		font-weight: 500;
		width: 100%;
		color: #777777;
		font-weight: 600;
		margin-bottom: 5px;
	}

	.selected-filter {
		font-size: 12px;
		color: $textBlack;
		padding: 7px 15px;
		background-color: $lightHighlightBackgound;
		border-radius: 18px;
		margin-right: 10px;
		margin-bottom: 10px;

		.remove-filter {
			margin-left:7px;
			cursor: pointer;
			
			img {
				width: 6px;
				height: 6px;
				margin-top: -1px;
			}
		}
	}

	.form-check {
		padding-left: 1.5rem;
		margin:5px;

		label {
			font-size: 12px;
		}

		.form-check-input {
			margin-top: 0.2rem;
			margin-left: -1.5rem;
		}
	}

	#formTeamSearch {
		font-size: 12px;
		border-radius: 20px;
		background-color: #F2F2F2;
		border: none;
		padding-left:20px;
		height: 40px;
	}

	.team-form {
		width: 100%;
		padding-right: 10px;

		.team-search-box {
			margin-top: 5px;
		}

		.form-group-search {
			padding: 5px 5px;
		}
	}
}

.search-inner-box {
	
	.give-search-box {
		font-size: 12px;
		border-radius: 20px;
		background-color: #F2F2F2;
		color: #AAAAAA;
		border: none;
		padding-left:25px;
		height: 40px;
		margin-top: 12px;
	}
}

.company-top-area {
	float:left;
	width:100%;
	height:65px;
	background-color:$orangeColor;
}

.company-logo-box {
	float:left;
	margin-left: 110px;
	margin-top: 10px;
	position: absolute;

	.company-logo {
		width: 50%;
	}
}


body {
  font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	line-height: 1.4em;
	color: #464646;
	margin: 0 auto;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	font-smoothing: antialiased;
	font-weight: 300;
	background-color:$lightGrey;
}

.feed-avatar {
	position:absolute;
	width:100%;
}

.user-list-group, .user-list-outer-box {
	width:100%;
	padding-left:0px;
}

.avatar-wrapper {
	height:50px;
	width:50px;
	overflow:hidden;
	border-radius:50%;
	position:relative;
	margin:0px auto;
	background-color:#ded;

	.initial-box {
		height: 100%;
		width: 100%;
		font-size: 1.4rem;
		text-align: center;
		padding: 15px 5px;
		font-weight: 600;
		color: #666666;
	}
}

.avatar-smallFont {
	.initial-box {
		font-size: 0.75rem;
		padding-top: 9px;
	}
}

.text-font {
	font-family: $secondaryFont;
}

td {
	padding: 12px;
}

h2 {
	color: $vin-blue;
}

.text-light-grey {
	color:$textLightGrey;
}

.text-black {
	color:$textBlack;
}

.savings { color: $vin-green; }
.loss { color: $vin-red; }
input.small { width: 46px; }
